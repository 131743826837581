/* ================ Tabs & Form ================ */

.bottomWrapper {
  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
  margin-top: 60px;
  align-self: stretch;

  @media (--viewportMedium) {
    margin-top: 72px;
  }
}

/* Terms of Service modal */

.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.termsHeading {
  margin: 0 0 19px 0;

  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  /* Position inside modal */
  position: fixed;
  top: 0;
  left: 0;

  /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: center;
  width: auto;

  /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;

  /* Colors */
  background-color: transparent;
  color: var(--matterColor);
  cursor: pointer;

  @media (--viewportMedium) {
    padding: 27px 30px;
    position: absolute;
  }

  &:disabled {
    background-color: transparent;
  }

  &:enabled:hover {
    background-color: transparent;
    color: var(--matterColorDark);
    box-shadow: none;
    text-decoration: none;
  }

  &:enabled:active {
    background-color: transparent;
    color: var(--matterColorDark);
  }
}

/* ================ Typography ================ */

/* Make the email pop */
.email {
  font-weight: var(--fontWeightBold);
}

/* Helper links */
.modalHelperLink {
  color: var(--matterColor);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}
